import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import {SciChartSurface} from "scichart/Charting/Visuals/SciChartSurface";
import {NumericAxis} from "scichart/Charting/Visuals/Axis/NumericAxis";
import {XyDataSeries} from "scichart/Charting/Model/XyDataSeries";

import {FastLineRenderableSeries} from "scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries"

import {EAxisAlignment} from 'scichart/types/AxisAlignment';
import {EDragMode} from "scichart/types/DragMode";

import {NumberRange} from "scichart/Core/NumberRange";
import {ENumericFormat} from "scichart/types/NumericFormat";
import {ELineDrawMode} from "scichart/Charting/Drawing/WebGlRenderContext2D";
import {DateTimeNumericAxis, EResamplingMode, MemoryUsageHelper} from 'scichart';
import {EExecuteOn} from "scichart/types/ExecuteOn";
import {easing} from "scichart/Core/Animations/EasingFunctions";

import {XAxisDragModifier} from "scichart/Charting/ChartModifiers/XAxisDragModifier";
import {RolloverModifier} from "scichart/Charting/ChartModifiers/RolloverModifier";
import {RubberBandXyZoomModifier} from "scichart/Charting/ChartModifiers/RubberBandXyZoomModifier";
import {ZoomExtentsModifier} from "scichart/Charting/ChartModifiers/ZoomExtentsModifier";

import { DpiHelper } from 'scichart/Charting/Visuals/TextureManager/DpiHelper';
import {EZoomState} from "scichart/types/ZoomState";

import { HorizontalLineAnnotation } from "scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation";

import {MdtYAxisPosition} from "../../../../state/common/dataExplorationChart/mdtYAxisPosition";
import { mdtPalette } from "../../../common/styles/mdtPalette";
import {SensorValueTickProvider} from "../interactive/sensorValueTickProvider";
import {YAxisDragModifier} from "scichart/Charting/ChartModifiers/YAxisDragModifier";
import CustomCardChartLegendModifier from "./customCardChartLegendModifier";

let primaryDataBackFill = { };
let secondaryDataBackFill = { };
/**
 * Modifier definitions to be used on this chart
 * @returns List of modifier definitions
 */
const modifiers = (showTooltips) => 
{
  const rolloverModifierOptions = {
    rolloverLineStroke: mdtPalette().typography.white,
    rolloverLineStrokeThickness: 0.25,
    showRolloverLine: true,
    showTooltip: showTooltips,
    allowTooltipOverlapping: false,
    snapToDataPoint: false
  }
  if (showTooltips === true) {
    rolloverModifierOptions.tooltipDataTemplate = metadataTooltipDataTemplate
  }

  return [
    {
      name: "XAxisDragModifier",
      isEnabled: true,
      options: {
        dragMode: EDragMode.Panning,
      }
    },
    {
      name: "RolloverModifier",
      isEnabled: true,
      options: rolloverModifierOptions
    },
    // This lets us zoom in using the right mouse button
    {
      name: "RubberBandXyZoomModifier",
      isEnabled: true,
      options: {
        id: uuidv4(),
        executeOn: EExecuteOn.MouseRightButton,
        easingFunction: easing.inOutSine
      }
    },
    // This lets us zoom back out to the original view by double clicking
    {
      name: "ZoomExtentsModifier",
      isEnabled: true,
      options: {
        id: uuidv4(),
        isAnimated: true,
        animationDuration: 400,
        easingFunction: easing.outExpo
      }
    },
    // This lets us scale the y axis by dragging the y axis
    {
      name: "YAxisDragModifier",
      isEnabled: true,
      options: {
        dragMode: EDragMode.Scaling,
      }
    },
    {
      name: "CustomCardChartLegendModifier",
      isEnabled: true
    }
  ]
}

/**
 * Returns the data to show inside the tooltip
 * @param {*} seriesInfo 
 * @returns 
 */
const metadataTooltipDataTemplate = (seriesInfo) => {
  const valuesWithLabels = [];

  const seriesNameParts = seriesInfo.seriesName.replaceAll('(', ' ').replaceAll(')', ' ').trim().split(' ');
  const uom = seriesNameParts.pop();
  const truckName = _.join(_.slice(seriesNameParts, 0, 1), ' ');

  valuesWithLabels.push(`${truckName} @ ${seriesInfo.yValue.toFixed(2)} ${uom}&#9; `);

  return valuesWithLabels;
};

/**
 * Given a collection of modifiers, add them to the surface.
 * @param {*} surface Parent surface
 * @param {*} modifiers Collection of modifier definitions to add
 * @param {*} primaryZeroRanges Property to determine if the primary x-axis should be zeroed or not
 * @param {*} secondaryZeroRanges Property to determine if the secondary x-axis should be zeroed or not
 * @returns void
 */
const addModifiers = (surface, modifiers, onRollOver) => {

  if (_.isNil(surface)) return;
  if (_.isNil(modifiers)) return;

  surface.chartModifiers.clear();
  modifiers.forEach(m => {
    if (m.isEnabled) {
      switch (m.name) {
        case "XAxisDragModifier":
          surface.chartModifiers.add(new XAxisDragModifier(m.options));
          break;
        case "RolloverModifier":
          surface.chartModifiers.add(new RolloverModifier({
            ...m.options,
            xAxisId: surface.xAxes.get(0).id,
            yAxisId: surface.yAxes.get(0).id
          }));
          break;
        case "RubberBandXyZoomModifier":
          surface.chartModifiers.add( new RubberBandXyZoomModifier({...m.options, xAxisId: surface.xAxes.get(0).id, yAxisId: surface.yAxes.get(0).id}));
          break;
        case "ZoomExtentsModifier":
          surface.chartModifiers.add(new ZoomExtentsModifier());
          break;
        case "YAxisDragModifier":
          surface.chartModifiers.add(new YAxisDragModifier(m.options));
          break;
        case "CustomCardChartLegendModifier":
          surface.chartModifiers.add(new CustomCardChartLegendModifier(onRollOver));
          break;
      }
    }
  });
};

/**
 * Supports annotations created for a specific xAxisId 
 *  - adds HorizontalLineAnnotations to the surface
 * 
 * TODO: Support annotations for the yAxis
 * @param {*} surface Parent surface
 * @param {*} annotations List of annotations to add 
 * @param {*} xAxisId xAxis to add annotations to
 * @returns void
 */
const addAnnotations = (surface, annotations, xAxisId) => {
  if (_.isNil(surface)) return;
  if (_.isNil(annotations)) return;

  _.remove(surface.annotations.items, (annotation) => { 
    return annotation.xAxisIdProperty === xAxisId;
  });

  annotations.forEach(a => {
    if (!_.isEmpty(a.y1)) {
      let annotation = new HorizontalLineAnnotation({
        y1: Number(a.y1),
        labelPlacement: a.labelPlacement,
        stroke: a.stroke,
        strokeThickness: a.strokeThickness,
        axisLabelFill: a.axisLabelFill,
        showLabel: true,
        labelValue: a.y1,
        xAxisId: xAxisId,
        yAxisId: Y_AXIS_IDS[0],
      });
      surface.annotations.add(annotation);
    }
    if (!_.isEmpty(a.y2)) {
      let annotation = new HorizontalLineAnnotation({
        y1: Number(a.y2),
        labelPlacement: a.labelPlacement,
        stroke: a.stroke,
        strokeThickness: a.strokeThickness,
        axisLabelFill: a.axisLabelFill,
        showLabel: true,
        labelValue: a.y2,
        xAxisId: xAxisId,
        yAxisId: Y_AXIS_IDS[0],
      });
      surface.annotations.add(annotation);
    }
  });
}

/**
 * Highlights the given context by setting opacity and stroke thickness for the context
 * and reducing the opacity and stroke thickness for all other contexts
 * If context is null, resets all contexts to default opacity and stroke thickness
 * @param {*} surface Parent surface
 * @param {*} context Given context to highlight; null if none
 * @returns void
 */
const highlightContext = (surface, context) => {
  if (_.isNil(surface)) return;
  if (_.isNil(context)) {
    _.forEach(surface.renderableSeries.asArray(), (series) => {
      series.opacity = 0.8;
      series.strokeThickness = 2;
    });
  } else {
    _.forEach(surface.renderableSeries.asArray(), (series) => {
      if (series.id === context) {
        series.opacity = 1;
        series.strokeThickness = 2;
      }
      else {
        series.opacity = 0.1;
        series.strokeThickness = 0.1;
      }
    });
  }
}

// Use individual debounce functions for each axis to prevent multiple axis updates from interfering with each other
const primaryXaxisDebounce = _.debounce((cb, axisId, min, max) => cb(axisId, min, max), 500);
const secondaryXaxisDebounce = _.debounce((cb, axisId, min, max) => cb(axisId, min, max), 500);
const leftOuterYaxisDebounce = _.debounce((cb, axisId, min, max) => cb(axisId, min, max), 500);
const leftInnerYaxisDebounce = _.debounce((cb, axisId, min, max) => cb(axisId, min, max), 500);
const rightInnerYaxisDebounce = _.debounce((cb, axisId, min, max) => cb(axisId, min, max), 500);
const rightOuterYaxisDebounce = _.debounce((cb, axisId, min, max) => cb(axisId, min, max), 500);

/**
 * Load chart surface
 */
async function initChart(chartRef, chartRootName) {

  DpiHelper.IsDpiScaleEnabled = false;

  const {sciChartSurface, wasmContext} = await SciChartSurface.create(
    chartRootName
  );

  sciChartSurface.background = mdtPalette().materialUI.palette.background.paper;
  sciChartSurface.viewportBorder = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    color: mdtPalette().categories.category1
  };

  chartRef.sciChartSurface = sciChartSurface;
  chartRef.wasmContext = wasmContext;

  return {sciChartSurface, wasmContext}
}

/**
 * Creates initial xAxis, only 'primary' is visible
 */
const initXAxis = (surface, wasm, xAxes, onXAxisVisibleRangeChanged, primaryDefinition, secondaryDefinition, xValues) => {
  if (_.isNil(surface)) return;
  if (_.isNil(wasm)) return;
  // create all the xAxis but only show the primary to start
  X_AXIS_IDS.forEach((pos, i) => {
    const xAxis = new DateTimeNumericAxis(wasm);
    xAxis.id = pos;
    xAxis.isVisible = (pos === X_AXIS_IDS[0] ? primaryDefinition.isVisible : secondaryDefinition.isVisible);
    xAxis.drawMajorGridLines = false;
    xAxis.drawMinorGridLines = false;
    xAxis.drawMajorBands = false;
    xAxis.drawMinorTickLines = false;
    xAxis.autoTicks = true;
    // maxAutoTicks should be equal to the largest number of hours this chart can render
    // Otherwise you can run into rangeExceededErrors if you have more hours of data than maxAutoTicks
    // ie. maxAutoTicks = 10 and you have 12 hours of data
    xAxis.maxAutoTicks = 24; 
    xAxis.axisAlignment = (pos === X_AXIS_IDS[0] ? primaryDefinition.placement : secondaryDefinition.placement);

    xAxis.labelStyle = {
      // same style as UnitCharts
      color: '#BDBDBD', // Material UI Grey 400, because mdtPallete().typography.color is still too bright
      fontFamily: 'Roboto',
      fontSize: 10, // larger than our normal charts
    };

    xAxis.labelProvider.formatLabel = (epochSeconds) => {
      // Displayed value is: 06/04, 23:10
      return moment(epochSeconds * 1000).format('MM/DD, HH:mm');
    };

    xAxis.visibleRangeChanged.subscribe((args) => {
      const min = Math.ceil(args.visibleRange.min);
      const max = Math.floor(args.visibleRange.max);
      if (xAxis.id === X_AXIS_IDS[0] && _.includes(xAxes, pos)) {
        primaryXaxisDebounce(onXAxisVisibleRangeChanged, xAxis.id, min, max);
      }
      else if (xAxis.id === X_AXIS_IDS[1] && _.includes(xAxes, pos)) {
        secondaryXaxisDebounce(onXAxisVisibleRangeChanged, xAxis.id, min, max);
      }
    });
    surface.xAxes.add(xAxis);
  });
};

/**
 * Creates initial yAxis, only 1 on left is initially visible
 *
 */
const initYAxis = (surface, wasm, yAxes, onYAxisVisibleRangeChanged) => {
  if (_.isNil(surface)) return;
  if (_.isNil(wasm)) return;
  // create all the yAxis but only show the first one
  Y_AXIS_IDS.forEach((pos, i) => {
    const yAxis = new NumericAxis(wasm);
    yAxis.isVisible = false;
    yAxis.id = pos;
    // yAxis.axisTitle = `${"-".repeat(5)}`;
    //todo: remove yaxis titles once happy they are ok
    // yAxis.axisTitle = pos;
    yAxis.axisAlignment = i < 2 ? EAxisAlignment.Left : EAxisAlignment.Right;

    // Don't use a specific format (Decimal_0) because we need decimal values when zooming
    yAxis.labelProvider.numericFormat = ENumericFormat.NoFormat;
    yAxis.autoTicks = true;
    yAxis.maxAutoTicks = 5;
    yAxis.minorsPerMajor = 0;
    yAxis.tickProvider = new SensorValueTickProvider(wasm);

    yAxis.drawMajorGridLines = false;
    yAxis.drawMinorGridLines = false;
    yAxis.drawMajorBands = false;

    yAxis.labelStyle = {
      // same style as UnitCharts
      color: '#BDBDBD', // Material UI Grey 400, because mdtPallete().typography.color is still too bright
      fontFamily: 'Roboto',
      fontSize: 10, // larger than our normal charts
    };

    yAxis.visibleRangeChanged.subscribe((args) => {
      const min = Math.ceil(args.visibleRange.min);
      const max = Math.floor(args.visibleRange.max);
      if (yAxis.id === Y_AXIS_IDS[0] && _.includes(yAxes, pos)) {
        leftInnerYaxisDebounce(onYAxisVisibleRangeChanged, yAxis.id, min, max);
      }
      else if (yAxis.id === Y_AXIS_IDS[1] && _.includes(yAxes, pos)) {
        leftOuterYaxisDebounce(onYAxisVisibleRangeChanged, yAxis.id, min, max);
      }
      else if (yAxis.id === Y_AXIS_IDS[2] && _.includes(yAxes, pos)) {
        rightInnerYaxisDebounce(onYAxisVisibleRangeChanged, yAxis.id, min, max);
      }
      else if (yAxis.id === Y_AXIS_IDS[3] && _.includes(yAxes, pos)) {
        rightOuterYaxisDebounce(onYAxisVisibleRangeChanged, yAxis.id, min, max);
      }
    });

    //todo: should we put a limit on number of yAxis?
    surface.yAxes.add(yAxis);
  });

};

/**
 * Ensures the YAxis is aligned correctly if it is used
 * @param {*} surface Parent surface
 * @returns void
 */
const syncYAxisVisibilityAndRotate = (surface) => {
  if (_.isNil(surface)) return;
  // find the used ones
  const usedYaxis = new Set(surface.renderableSeries.asArray().filter(s => s.isVisible).map(series => series.yAxisId));

  // remove the others
  surface.yAxes.asArray().forEach((y, i) => {
    y.isVisible = usedYaxis.has(y.id) !== y.isVisible ? usedYaxis.has(y.id) : usedYaxis.has(y.id);
    y.axisAlignment = i < 2 ? EAxisAlignment.Left : EAxisAlignment.Right;
    y.flippedCoordinates = false;
  });
};

/**
 * Ensures the YAxis has the right min and max values
 * @param {*} surface Parent surface
 * @param {*} primaryZeroRanges Property to determine if the y-axis should be zeroed or not for the primary XAxis
 * @param {*} secondaryZeroRanges Property to determine if the y-axis should be zeroed or not for the secondary XAxis
 * @returns void
 */
const calculateYAxisRanges = (surface, primaryZeroRanges, secondaryZeroRanges) => {
  // resize all y axes to fit their visible data series with a 5% padding
  const usedYaxis = surface.renderableSeries.asArray().filter(s => s.isVisible).map(series => ({ yAxis: series.yAxisId, xAxis: series.xAxisId }));
  const uniqueUsedYAxis = _.uniqBy(usedYaxis, 'yAxis');
  uniqueUsedYAxis.forEach(axes => {
    // determine range of values for this axis
    const axis = surface.getYAxisById(axes.yAxis);

    // determine combined range for all series of this axis
    let min = null;
    let max = null;
    surface.renderableSeries.asArray().filter(s => s.yAxisId === axes.yAxis && s.isVisible === true).forEach(series => {
      const yValues = series.getYRange(series.getXRange(), false);
      // yValues can be undefined at the start
      if (!_.isNil(yValues)) {
        if (_.isNil(min)) {
          min = yValues.min;
        }
        else if (yValues.min < min) {
          min = yValues.min;
        }
        if (_.isNil(max)) {
          max = yValues.max;
        }
        else if (yValues.max > max) {
          max = yValues.max;
        }
      }
    });

    // pad the range to make things pretty
    const padding = (max - min) * 0.05;
    // TODO: we should round to a nice round number (based on the value, ie. 1805 -> 2000)
    //       - wait until we add TickProviders and all that logic can live together -- FL
    min = Math.floor(min - padding);
    max = Math.ceil(max + padding);
    // console.log(`Resizing yAxisId=${axes.yAxis} to fit data: [${min}, ${max}]`);
    const zeroRanges = axes.xAxis === X_AXIS_IDS[0] ? primaryZeroRanges : secondaryZeroRanges;
    axis.visibleRange = new NumberRange((zeroRanges === true ? 0 : min), max);
  });
}

/**
 * Ensures the X Axis is aligned properly if used.
 * @param {*} surface Parent surface
 * @returns void
 */
const syncXAxisVisibilityAndRotate = (surface) => {
  if (_.isNil(surface)) return;
  // find the used ones
  const usedXaxis = new Set(surface.renderableSeries.asArray().filter(s => s.isVisible).map(series => series.xAxisId));
  surface.xAxes.asArray().forEach(x => {
    x.isVisible = usedXaxis.has(x.id);
  });
};

const updateAxes = (surface, primaryZeroRanges, secondaryZeroRanges) => {
  syncXAxisVisibilityAndRotate(surface);
  syncYAxisVisibilityAndRotate(surface);
  calculateYAxisRanges(surface, primaryZeroRanges, secondaryZeroRanges);
}

const resetZoom = (surface, primaryZeroRanges, secondaryZeroRanges) => {
  surface.zoomExtentsX();
  updateAxes(surface, primaryZeroRanges, secondaryZeroRanges);
}

/**
 * Generates a object that contains all renderable series keyed by their name
 * @returns an object of {}'dataSeriesName':[renderableSeries]}
 */
const getRenderableSeriesBySeriesName = (surface, xAxisId) => {
  /**
   * Get all the renderableSeries for the xAxisId (primary/secondary)
   * and group them by their DataSeriesName {context alias (uom)}
   */
  const results = surface.renderableSeries.asArray().filter(s => s.xAxisId === xAxisId);
  return _.groupBy(results, (item) => {
    return item.getDataSeriesName()
  });
};

/**
 * generate unique id for series based on context, sensor alias and sensor uom.
 */
const seriesPK = (context, sensor) => {
  return `${context?.name};${sensor?.alias};(${sensor?.uom})`;
};

/**
 * Checks the value of the multipleContexts property to determine if we are dealing with a single context or multiple contexts
 * @param {*} multipleContexts A property that determines if we are dealing with a single context or multiple contexts
 * @returns True if multipleContexts is False, False if multipleContexts is True
 */
const hasSingleContext = (multipleContexts) => {
  return multipleContexts === false;
}

/**
 * Handles when the data to chart has changed.
 */
const onDataChange = (surface, wasm, xAxisId, definition, xValues, yValues, shouldUpdateChartOnDataChange, multipleContexts) => {
  if (_.isNil(surface)) return;
  if (_.isNil(wasm)) return;
  
  if (!_.isNil(shouldUpdateChartOnDataChange) && shouldUpdateChartOnDataChange === false) return;

  let haveSeriesChanged = false;

  // data has changed for the primary xAxis
  // get all renderable series on the primary xAxis
  // grouped by DataSeriesName
  const seriesBySeriesName = getRenderableSeriesBySeriesName(surface, xAxisId);

  // Single Context, Multiple Sensors
  if (hasSingleContext(multipleContexts)) {    
    // check for an existing renderableSeries
    // if not found create a new renderable series
    // if found update the data and remove the renderableSeries from the map
    // when done delete any remaining renderableSeries as they are no longer needed. ie) user removed a sensor
    _.sortBy(definition.sensors, ['yAxisId']).forEach((sensor, index) => {
      const curContext = definition.contexts[index];
      const curSensor = sensor;
      const curPK = seriesPK(curContext, curSensor);

      if (!_.isNil(curContext)) {
        const yValueLen = _.isNil(yValues) || _.isNil(yValues[sensor.sensorSetId]) ? 0 : yValues[sensor.sensorSetId].length;

        if (seriesBySeriesName.hasOwnProperty(curPK)) {
          delete seriesBySeriesName[curPK];
        }

        // Creating a New Series from scratch
        if (!seriesBySeriesName.hasOwnProperty(curPK)) {
          //only create renderableSeries when there is data available for the sensor
          if (yValueLen > 0) {
            const curDataSeries = new XyDataSeries(
              wasm, 
              {
                xValues: xValues,
                yValues: yValues[sensor.sensorSetId],
                dataSeriesName: curPK,
                isSorted: true,
                // According to SciChart, setting these explicitly here speeds up performance
                // Otherwise SciChart has to take time to figure it out
                dataIsSortedInX: true,
                dataEvenlySpacedInX: false,
                containsNaN: true,
              });

            const seriesOption = {
              opacity: 0.8,
              zeroLineY: 0,
              strokeThickness: 2,
              stroke: curSensor.color,
              fill: curSensor.color,
          
              xAxisId: xAxisId,
              yAxisId: translateMdtYAxisId(curSensor.yAxisId),
              isVisible: curSensor.isVisible,
          
              dataSeries: curDataSeries,
              drawNaNAs: ELineDrawMode.DiscontinuousLine,
            };
            
            const renderable = new FastLineRenderableSeries(wasm, seriesOption);
          
            //todo: here would be a good place to do a size check, for say ..  performance or subscription reasons
            surface.renderableSeries.add(renderable);

            // Axis should always be visible
            getYAxisFromMdtId(surface, curSensor.yAxisId).isVisible = true;

            haveSeriesChanged = true;
          }
          return;
        }
      }
    });
  }
  // Multiple Contexts, Single Sensor 
  else {
    // If we are clearing data
    if (xValues.length === 0 && yValues.length === 0) {
      _.forEach(surface.renderableSeries.items, (series) => {
        series.dataSeries.delete();
        surface.renderableSeries.remove(series);
      })
      resetZoom(surface, definition.zeroYRanges, definition.zeroYRanges);
    }
    else {
      // For multiple contexts, the data is treated as a "create and update". 
      // The first time through a onDataChange for multiple contexts, we will create the data series
      // The subsequent times through, we will update the data series
      // And we also give the ability to clear out ALL data series (see above)
      definition.contexts.forEach((context, index) => {
        const curSensor = definition.sensors[0];
        const curPK = seriesPK(context, curSensor);

        const yValueLen = _.isNil(yValues) || _.isNil(yValues[context.id]) ? 0 : yValues[context.id].length;

        // An existing series
        if (seriesBySeriesName.hasOwnProperty(curPK)) {
          // The newest data is already appended to the end so let's update the series with that data
          // since the FiFo capacity is set to the length of the data, we don't need to worry about removing old data
          const foundRenderableSeries = seriesBySeriesName[curPK][0];

          // First check if we have any backfill data to add
          if (xAxisId === X_AXIS_IDS[0] && !_.isNil(primaryDataBackFill[curPK]) && (!_.isEmpty(primaryDataBackFill[curPK].xValues)) && (!_.isEmpty(primaryDataBackFill[curPK].yValues))) {
            foundRenderableSeries.dataSeries.appendRange(primaryDataBackFill[curPK].xValues, primaryDataBackFill[curPK].yValues);

            primaryDataBackFill[curPK].xValues = [];
            primaryDataBackFill[curPK].yValues = [];
          }
          else if (xAxisId === X_AXIS_IDS[1] && !_.isNil(secondaryDataBackFill[curPK]) && (!_.isEmpty(secondaryDataBackFill[curPK].xValues)) && (!_.isEmpty(secondaryDataBackFill[curPK].yValues))) {
            foundRenderableSeries.dataSeries.appendRange(secondaryDataBackFill[curPK].xValues, secondaryDataBackFill[curPK].yValues);

            secondaryDataBackFill[curPK].xValues = [];
            secondaryDataBackFill[curPK].yValues = [];
          }
          else {
            // CLAIM: the messages we receive on the websocket come in order
            // This data change method is called when the data (xValues and yValues) is updated
            // For a given context, the xValues and yValues should match up - so the index of the latest xValue
            // should also be the index of the latest yValue **for that context**

            // For new timestamps, the latest xValue will be greater than the max of the xRange

            // We are adding a new point because this timestamp is new
            if (xValues[xValues.length - 1] > foundRenderableSeries.dataSeries.xRange.max) {
              // There is .append as well to add a single point but .appendRange is faster and allows us to handle adding multiple points at once
              foundRenderableSeries.dataSeries.appendRange([xValues[xValues.length - 1]], [yValues[context.id][xValues.length - 1]]);
            }
            // We are updating the last point because this timestamp is not new (see CLAIM above) but the value
            // at this timestamp has changed
            else {
              foundRenderableSeries.dataSeries.update(xValues.length - 1, yValues[context.id][xValues.length - 1]);
            }
          }
        }
        // Creating a New Series from scratch
        else if (!seriesBySeriesName.hasOwnProperty(curPK)) {
          //only create renderableSeries when there is data available for the sensor
          if (yValueLen > 0) {
            const curDataSeries = new XyDataSeries(
              wasm, 
              {
                xValues: xValues,
                yValues: yValues[context.id],
                fifoCapacity: xValues.length,
                dataSeriesName: curPK,
                isSorted: true,
                // According to SciChart, setting these explicitly here speeds up performance
                // Otherwise SciChart has to take time to figure it out
                dataIsSortedInX: true,
                dataEvenlySpacedInX: true,
                containsNaN: true,
              });

            const seriesOption = {
              id: context.id,
              opacity: 0.8,
              strokeThickness: 2,
              stroke: context.color,
              fill: context.color, 
          
              xAxisId: xAxisId,
              yAxisId: translateMdtYAxisId(curSensor.yAxisId),
              isVisible: context.visible, 
          
              dataSeries: curDataSeries,
              drawNaNAs: (definition.closeGaps === false ? ELineDrawMode.DiscontinuousLine : ELineDrawMode.PolyLine),
            };
            const renderable = new FastLineRenderableSeries(wasm, seriesOption);
            renderable.resamplingMode = EResamplingMode.Auto;
            
            //todo: here would be a good place to do a size check, for say ..  performance or subscription reasons
            surface.renderableSeries.add(renderable);

            // Axis should always be visible
            getYAxisFromMdtId(surface, curSensor.yAxisId).isVisible = true;

            haveSeriesChanged = true;
          }
          return;
        }
      });
      
      // add annotations
      addAnnotations(surface, definition.annotations, xAxisId);
    }
  }

  if (hasSingleContext(multipleContexts)) {
    //any renderableSeries left should be removed
    for (const [dataSeriesName, renderableSeries] of Object.entries(seriesBySeriesName)) {
      renderableSeries.forEach((rs) => {
        //console.log(`Begin deleting renderableSeries for ${dataSeriesName}`);
        // delete data series to free up memory
        try {
          rs.dataSeries.delete();
        } catch (err) {
          // console.error(err.message);
        }
        surface.renderableSeries.remove(rs);
        // console.log(`End deleting renderableSeries for ${dataSeriesName}`);
      });
    }

    // reset the zoom then apply any custom axis visibility or ranges
    // even though resetZoom takes primary and secondary zeroRanges settings, here we use the same value for both
    // since onDataChange deals with one xAxis at a time
    resetZoom(surface, definition.zeroYRanges, definition.zeroYRanges);
  } else {
    if (surface.zoomState !== EZoomState.UserZooming) {

      const usedYaxis = surface.renderableSeries.asArray().filter(s => s.isVisible).map(series => ({ yAxis: series.yAxisId, xAxis: series.xAxisId }));
      const uniqueUsedYAxis = _.uniqBy(usedYaxis, 'yAxis');
      uniqueUsedYAxis.forEach(axes => {
        // determine range of values for this axis
        const yAxis = surface.getYAxisById(axes.yAxis);
        const xAxis = surface.getXAxisById(axes.xAxis);

        // determine combined range for all series of this axis
        let min = null;
        let max = null;
        surface.renderableSeries.asArray().filter(s => s.yAxisId === axes.yAxis && s.isVisible === true).forEach(series => {
          const yValues = series.getYRange(series.getXRange(), false);
          // yValues can be undefined at the start
          if (!_.isNil(yValues)) {
            if (_.isNil(min)) {
              min = yValues.min;
            }
            else if (yValues.min < min) {
              min = yValues.min;
            }
            if (_.isNil(max)) {
              max = yValues.max;
            }
            else if (yValues.max > max) {
              max = yValues.max;
            }
          }
        });

        const padding = (max - min) * 0.05;
        min = Math.floor(min - padding);
        max = Math.ceil(max + padding);

        if (xAxis.isVisible === true && definition.isVisible === true) {
          yAxis.visibleRange = new NumberRange((shouldZeroYRanges(definition.zeroYRanges, max) === true ? 0 : min), max);
        }
        surface.zoomExtentsX();
      });
    }
  }

  // Force a redraw of the chart
  surface.invalidateElement({force: true});
};

/**
 * Given the zeroYRanges property and the calculated max value (from the data), determine if we should continue with
 * zeroing the yRange or not. This will help in cases where all the data is below zero but because the zeroYRanges is set to true,
 * nothing is visible on the chart so it looks like something went wrong.
 * @param {*} zeroYRanges Setting on the Definition that says whether or not we should zero the yRange
 * @param {*} max The largest value in the current data set
 * @returns True or False based on the logic above
 */
const shouldZeroYRanges = (zeroYRanges, max) => {
  if (zeroYRanges === true) {
    if (max < 0) {
      return false;
    }
    return zeroYRanges;
  } else {
    return false;
  }
}

/**
 * Handles when the chart configuration has changed.
 */
const onConfigChange = (surface, xAxisId, definition, multipleContexts) => {
  if (_.isNil(surface)) return;
  if (_.isNil(definition)) return;

  // only config has changed
  const seriesBySeriesName = getRenderableSeriesBySeriesName(surface, xAxisId);

  surface.xAxes.getById(xAxisId).isVisible = definition.isVisible;

  if (!_.isNil(definition.axisTitle)) {
    surface.xAxes.getById(xAxisId).axisTitle = definition.axisTitle;
    surface.xAxes.getById(xAxisId).axisTitleStyle = {
      fontSize: 12,
      fontFamily: "Roboto",
      color: '#fff'
    }
  }

  if (hasSingleContext(multipleContexts)) {
    _.sortBy(definition.sensors, ['yAxisId']).forEach((sensor, index) => {
      const curContext = definition.contexts[index];
      const curSensor = sensor;
      if (!_.isNil(curContext)) {
        const curPK = _.isNil(curSensor) ? null : seriesPK(curContext, curSensor);

        // We've found a series with the given PK
        if (!_.isNil(curPK) && seriesBySeriesName.hasOwnProperty(curPK)) {
          const curRS = seriesBySeriesName[curPK][0];
          curRS.yAxisId = translateMdtYAxisId(curSensor.yAxisId);
          curRS.isVisible = curSensor.isVisible;
          curRS.stroke = curSensor.color;
          curRS.fill = curSensor.color;
          curRS.strokeDashArray = curSensor.lineStyle.value;
        }
      }
    });

    updateAxes(surface, definition.zeroYRanges, definition.zeroYRanges);
  } else {
    _.sortBy(definition.contexts, ['id']).forEach((context, index) => {
      const curSensor = definition.sensors[0];
      const curPK = _.isNil(curSensor) ? null : seriesPK(context, curSensor);

      // We've found a series with the given PK
      if (!_.isNil(curPK) && seriesBySeriesName.hasOwnProperty(curPK)) {
        const curRS = seriesBySeriesName[curPK][0];
        curRS.yAxisId = translateMdtYAxisId(curSensor.yAxisId);
        curRS.isVisible = context.visible;
        curRS.stroke = context.color;
        curRS.fill = context.color;
        curRS.strokeDashArray = [10,0];
        curRS.rolloverModifierProps.tooltipColor = context.color;
        curRS.drawNaNAs = (definition.closeGaps === false ? ELineDrawMode.DiscontinuousLine : ELineDrawMode.PolyLine);
      }
    });
  }

  // Force a redraw of the chart
  surface.invalidateElement({force: true});
};

/**
 * MDT defines Y-Axis position is a non-zero integer ordered from inner axis to
 * outer, with left axes being negative and right axes being positive. For
 * example four axes can be represented as
 *     -2 -1 [CHART] 1 2
 * But SciChart, we currently encode the axis differently as
 *     1 0 [CHART] 2 3
 * So we translate the position here.
 *
 * @param mdtYAxisId MDT Y-axis position identifier
 * @return {string} SciChart Y-axis identifier
 */
const translateMdtYAxisId = (mdtYAxisId) => {
  switch (mdtYAxisId) {
    case MdtYAxisPosition.LeftOuter:
      return "LEFT_OUTER";
    case MdtYAxisPosition.LeftInner:
      return "LEFT_INNER";
    case MdtYAxisPosition.RightInner:
      return "RIGHT_INNER";
    case MdtYAxisPosition.RightOuter:
      return "RIGHT_OUTER";
    default:
      console.error(`Invalid numerical Y-axis position '${mdtYAxisId}', defaulting to LEFT_INNER`);
      return "LEFT_INNER";
  }
}

/**
 * Convenience function to get an actual Y-Axis object given a MDT Y-Axis
 * position.
 *
 * @param surface surface object
 * @param mdtYAxisId MDT Y-axis position identifier
 * @return {*} Y-Axis object
 */
const getYAxisFromMdtId = (surface, mdtYAxisId) => {
  const yAxisId = translateMdtYAxisId(mdtYAxisId);
  return surface.getYAxisById(yAxisId);
}

const Y_AXIS_IDS = Object.freeze(["LEFT_INNER","LEFT_OUTER", "RIGHT_INNER", "RIGHT_OUTER"]);
const X_AXIS_IDS = Object.freeze(["primary", "secondary"]);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CardChart = (props) => {
  const [chart, setChart] = useState({});
  const [chartLoaded, setChartLoaded] = useState(false);

  const chartRootName = 'chart-card-root' + '_' + props.chartId;

  /**
   * Initialize the chart
   */
  useEffect(() => {
    // this variable is a dictionary so that we can use its properties byReference
    // rather than byValue.
    let chartRef = {};
    // Used to help fix an issue with "updating an unmounted component" warning from React
    let mounted = true;

    (async () => {
      // console.log('init chart');
      // initialize the chart surface
      await initChart(chartRef, chartRootName);
      initXAxis(chartRef.sciChartSurface, chartRef.wasmContext, props.xAxes, props.onXAxisVisibleRangeChanged, props.primaryDefinition, props.secondaryDefinition, props.primaryXValues);
      initYAxis(chartRef.sciChartSurface, chartRef.wasmContext, props.yAxes, props.onYAxisVisibleRangeChanged);
      if (mounted) {
        setChart(chartRef);
        setChartLoaded(true);
      }
    })();

    // returns a callback that is used to perform cleanup
    return () => {
      mounted = false;
      if (chartRef.sciChartSurface) {
        chartRef.sciChartSurface.delete();
      } else {
        // console.warn('empty chartRef surface');
      }
    };
  }, []);

  /**
   * Handles data change on the primary x axis
   */
  useEffect(() => {
    try {
      onDataChange(chart.sciChartSurface,
        chart.wasmContext,
        X_AXIS_IDS[0],
        props.primaryDefinition,
        props.primaryXValues,
        props.primaryYValues,
        props.shouldUpdateChartOnDataChange,
        props.multipleContexts);
      /**
       * For useEffect, if you specify a 'return () => { ... }' function, that will act as a 'prevProps' handler.
       * The onDataChange is applied to the current props.
       * See https://react.dev/reference/react/useEffect#usage
       * 
       * So for this case, this useEffect triggers on chartLoaded, xValues, and yValues
       * When yValues change, like when we switch from context to context, this 'return' function will make sure we explicitly delete the 
       * previous context's data from the chart so that the new context data can be loaded
       * 
       * This way, we don't get the occasional bug where we have two contexts data in the chart because we didn't clear the old
       * context data from the chart
       */
      return () => {
        if (chartLoaded) {
          if (hasSingleContext(props.multipleContexts)) {
            props.primaryDefinition.sensors.forEach((sensor, index) => {
              const curContext =  props.primaryDefinition.contexts[index];
              if (!_.isNil(curContext)) {
                const curSensor = sensor;
                const curPK = _.isNil(curSensor) ? null : seriesPK(curContext, curSensor);

                _.remove(chart.sciChartSurface.renderableSeries.items, (series) => { return series.getDataSeriesName() === curPK; });
              }
            });
          }
          else {
            // For multiple contexts, we should handle the case of needing to backfill data for when we have paused data updates
            if (props.shouldUpdateChartOnDataChange === false) {
              props.primaryDefinition.contexts.forEach((context, index) => {
                const curSensor = props.primaryDefinition.sensors[0];
                const curPK = seriesPK(context, curSensor);

                // data has changed for the primary xAxis
                // get all renderable series on the primary xAxis
                // grouped by DataSeriesName
                const seriesBySeriesName = getRenderableSeriesBySeriesName(chart.sciChartSurface, X_AXIS_IDS[0]);

                if (seriesBySeriesName.hasOwnProperty(curPK)) {
                  // Here we are going to backfill the data into memory so that when the user resumes live data,
                  // we will use this in memory data to fill the gaps
                  const newXValues = [props.primaryXValues[props.primaryXValues.length - 1]];
                  const newYValues = [props.primaryYValues[context.id][props.primaryXValues.length - 1]];

                  if (_.isNil(primaryDataBackFill[curPK])) {
                    primaryDataBackFill[curPK] = { xValues: [], yValues: [] };
                  }

                  primaryDataBackFill[curPK].xValues.push(...newXValues);
                  primaryDataBackFill[curPK].yValues.push(...newYValues);
                }
              });

            }
          }
        }
      }
    } catch (err) {
      console.error(`PrimaryData: ${err.message}`);
    }
  }, [chartLoaded, props.primaryXValues, props.primaryYValues]);

  /**
   * Handles data change on the secondary x axis
   */
  useEffect(() => {
    try {
      onDataChange(chart.sciChartSurface,
        chart.wasmContext,
        X_AXIS_IDS[1],
        props.secondaryDefinition,
        props.secondaryXValues,
        props.secondaryYValues,
        props.shouldUpdateChartOnDataChange,
        props.multipleContexts);
      /**
       * For useEffect, if you specify a 'return () => { ... }' function, that will act as a 'prevProps' handler.
       * The onDataChange is applied to the current props.
       * See https://react.dev/reference/react/useEffect#usage
       * 
       * So for this case, this useEffect triggers on chartLoaded, xValues, and yValues
       * When yValues change, like when we switch from context to context, this 'return' function will make sure we explicitly delete the 
       * previous context's data from the chart so that the new context data can be loaded
       * 
       * This way, we don't get the occasional bug where we have two contexts data in the chart because we didn't clear the old
       * context data from the chart
       */
      return () => {
        if (chartLoaded) {
          if (hasSingleContext(props.multipleContexts)) {
            props.secondaryDefinition.sensors.forEach((sensor, index) => {
              const curContext =  props.secondaryDefinition.contexts[index];
              if (!_.isNil(curContext)) {
                const curSensor = sensor;
                const curPK = _.isNil(curSensor) ? null : seriesPK(curContext, curSensor);

                _.remove(chart.sciChartSurface.renderableSeries.items, (series) => { return series.getDataSeriesName() === curPK; });
              }
            });
          }
          else {
            // For multiple contexts, we should handle the case of needing to backfill data for when we have paused data updates
            if (props.shouldUpdateChartOnDataChange === false) {
              props.secondaryDefinition.contexts.forEach((context, index) => {
                const curSensor = props.secondaryDefinition.sensors[0];
                const curPK = seriesPK(context, curSensor);

                // data has changed for the primary xAxis
                // get all renderable series on the secondary xAxis
                // grouped by DataSeriesName
                const seriesBySeriesName = getRenderableSeriesBySeriesName(chart.sciChartSurface, X_AXIS_IDS[1]);

                if (seriesBySeriesName.hasOwnProperty(curPK)) {
                  // Here we are going to backfill the data into memory so that when the user resumes live data,
                  // we will use this in memory data to fill the gaps
                  const newXValues = [props.secondaryXValues[props.secondaryXValues.length - 1]];
                  const newYValues = [props.secondaryYValues[context.id][props.secondaryXValues.length - 1]];

                  if (_.isNil(secondaryDataBackFill[curPK])) {
                    secondaryDataBackFill[curPK] = { xValues: [], yValues: [] };
                  }

                  secondaryDataBackFill[curPK].xValues.push(...newXValues);
                  secondaryDataBackFill[curPK].yValues.push(...newYValues);
                }
              });

            }
          }
        }
      }
    } catch (err) {
      console.error(`SecondaryData: ${err.message}`);
    }
  }, [chartLoaded, props.secondaryXValues, props.secondaryYValues]);

/**
  * Update the chart based on changes in the Legend (visibility, axis positions)
  */
  useEffect(() => {
    try {
      onConfigChange(chart.sciChartSurface, X_AXIS_IDS[0], props.primaryDefinition, props.multipleContexts);
    } catch (err) {
      console.error(`PrimaryConfig: ${err.message}`);
    }
  }, [chartLoaded, props.primaryDefinition.sensors, props.primaryDefinition.contexts, props.shouldRefreshChart]);

  /**
   * Update the chart based on changes in the Legend (visibility, axis positions)
   */
  useEffect(() => {
    try {
      onConfigChange(chart.sciChartSurface, X_AXIS_IDS[1], props.secondaryDefinition, props.multipleContexts);
    } catch (err) {
      console.error(`SecondaryConfig: ${err.message}`);
    }
  }, [chartLoaded, props.secondaryDefinition.sensors, props.secondaryDefinition.contexts, props.shouldRefreshChart]);

  useEffect(() => {
    try {
      addModifiers(chart.sciChartSurface, modifiers(props.showTooltips), props.onRollOver);
    } catch (err) {
      console.error(`AddModifiers: ${err.message}`);
    }
  }, [chartLoaded]);

  useEffect(() => {
    try {
      addAnnotations(chart.sciChartSurface, props.primaryDefinition.annotations, X_AXIS_IDS[0]);
    } catch (e) {
      console.error(`AddAnnotations Primary: ${e.message}`);
    }
  }, [props.primaryDefinition.annotations, props.shouldRefreshChart]);

  useEffect(() => {
    try {
      addAnnotations(chart.sciChartSurface, props.secondaryDefinition.annotations, X_AXIS_IDS[1]);
    } catch (e) {
      console.error(`AddAnnotations Secondary: ${e.message}`);
    }
  }, [props.secondaryDefinition.annotations, props.shouldRefreshChart]);

  useEffect(() => {
    try {
      highlightContext(chart.sciChartSurface, props.primaryDefinition.highlightContext);
    } catch (e) {
      console.error(`HighlightContext Primary: ${e.message}`);
    }
  }, [props.primaryDefinition.highlightContext, props.shouldRefreshChart]);

  useEffect(() => {
    try {
      highlightContext(chart.sciChartSurface, props.secondaryDefinition.highlightContext);
    } catch (e) {
      console.error(`HighlightContext Secondary: ${e.message}`);
    }
  }, [props.secondaryDefinition.highlightContext, props.shouldRefreshChart]);
  

  return (
    <div style={props.chartContainerStyle}>
      <div id={chartRootName} style={{maxHeight: '100%', minHeight: '100%', maxWidth: '100%', minWidth: '100%'}}/>
    </div>
  );

};

CardChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  chartContainerStyle: PropTypes.object.isRequired
}

export {
  CardChart,
  X_AXIS_IDS,
  Y_AXIS_IDS
};
